import React from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

import Layout from "../components/layout"
import { siteMetadata } from "../../gatsby-config"
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

const useStyles = makeStyles((theme) => ({
  content: {
  },
}))

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location,
}) {
  const classes = useStyles();
  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx
  const shortcodes = { Link } // Provide common components here

  let disqusConfig = {
    url: `${siteMetadata.siteUrl+location.pathname}`,
    identifier: frontmatter.slug,
    title: frontmatter.title,
  }

  return <Layout>
    <div className="blog-post-container">
      <div className="blog-post">
        <h1>{frontmatter.title}</h1>
        <Typography variant="subtitle1" color="textSecondary" component="p">{frontmatter.date}</Typography>
        {frontmatter.categories && frontmatter.categories.length > 1 &&
          <div>
            Tag: [ {frontmatter.categories.join(', ')} ]
          </div>
        }
        <CommentCount config={disqusConfig} placeholder={'...'} />
        <MDXProvider components={shortcodes} className={classes.content}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        <Disqus config={disqusConfig} />
      </div>
    </div>
  </Layout>
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        categories
        slug
        title
      }
    }
  }
`